import React, { Component, Suspense } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import {
  Container, Row, Col, Button,
} from 'reactstrap';
import {
  AppBreadcrumb, AppFooter, AppHeader, AppSidebar, AppSidebarFooter,
  AppSidebarForm, AppSidebarHeader, AppSidebarMinimizer, AppSidebarNav,
} from '@coreui/react';
// eslint-disable-next-line import/no-cycle
import routes from '../routes/routes';
import DefaultFooter from './defaultFooter';
import navigation from '../routes/nav';
import DefaultHeader from './defaultHeader';
import { getUserPermissions } from '../actions/permissions';
import Loading from '../components/Loading/Loading';

class DefaultLayout extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      ShowInfo: true,
      PageName: '',
      permissionsLoaded: false
    };
  }

  componentDidMount() {
    this.props.getUserPermissions();
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { history } = nextProps;
    const { PageName, ShowInfo } = nextState;
    const { permissions } = this.props;

    if (permissions?.data && !this.state.permissionsLoaded) {
      this.setState({ permissionsLoaded: true });
    }

    if (history.location.pathname !== PageName && PageName.length > 0 && !ShowInfo) {
      this.setState({ ShowInfo: true });

      return false;
    }

    return true;
  }

  hasPermission = (routeGroups) => {
    const { permissions } = this.props;
    const permissionGroups = permissions?.data?.permissionGroups || [];
    const isSuperAdmin = permissions?.data?.superAdmin || false;

    if (!permissionGroups.length && !isSuperAdmin) {
      return false;
    }

    if (!routeGroups || !Array.isArray(routeGroups)) {
      return false;
    }

    if (isSuperAdmin) {
      return true;
    }

    const hasAccess = routeGroups.some((routeGroup) =>
      permissionGroups.includes(routeGroup)
    );

    return hasAccess;
  }

  render() {
    const { ShowInfo } = this.state;
    const { history, permissions } = this.props;
    const env = process.env.REACT_APP_STAGE && process.env.REACT_APP_STAGE.trim();
    if (!navigation || !navigation.items) {
      return null;
    }

    if (permissions.loading) {
      return <Loading />;
    }

    if (navigation && navigation.items) {
      navigation.items = navigation.items.filter(
        (item) => {
          const groups = Array.isArray(item.groups) ? item.groups : [item.groups];
          return this.hasPermission(groups);
        }
      );
    }
    const filteredRoute = routes.filter(
      (route) => {
        if (!route || !route.groups) return false;
        const groups = Array.isArray(route.groups) ? route.groups : [route.groups];
        return this.hasPermission(groups);
      }
    );

    const validRoutes = filteredRoute.filter((route) =>
      route &&
      route.path &&
      !route.path.split('/').pop().includes(':')
    );

    let firstPath = '/dashboard';
    if (validRoutes.length > 0) {
      const defaultRoute = validRoutes.find((route) =>
        route.path !== '/' && route.path !== '/dashboard'
      ) || validRoutes[0];

      firstPath = defaultRoute ? defaultRoute.path : '/dashboard';
    }

    return (
      <div className="app">
        <AppHeader fixed>
          <Suspense fallback={<Loading />}>
            <DefaultHeader />
          </Suspense>
        </AppHeader>
        {env === 'HML' && ShowInfo && (
          <>
            <div className="container-identifier" />
            <Row className="identifier-environmment">
              <Col xs="1" />
              <Col xs="10" className="d-flex justify-content-center">
                Você está acessando o ambiente de teste !
              </Col>
              <Col xs="1" className="p-0">
                <Button
                  className="m-0 bg-transparent border-0"
                  type="button"
                  onClick={() => {
                    this.setState({ ShowInfo: false, PageName: history.location.pathname });
                  }}
                  onKeyDown={() => {
                    this.setState({ ShowInfo: false, PageName: history.location.pathname });
                  }}
                >
                  <i
                    className="fa fa-close text-white"
                  />
                </Button>
              </Col>
            </Row>
          </>
        )}
        <div className="app-body">
          <AppSidebar fixed display="lg">
            <AppSidebarHeader />
            <AppSidebarForm />
            <Suspense fallback={<Loading />}>
              <AppSidebarNav
                navConfig={navigation}
                location={this.props.location}
                history={this.props.history}
              />
            </Suspense>
            <AppSidebarFooter />
            <AppSidebarMinimizer />
          </AppSidebar>
          <main className="main">
            <AppBreadcrumb appRoutes={routes} />
            <Container fluid>
              <Suspense fallback={<Loading />}>
                <Switch>
                  {filteredRoute.map((route) => (
                    route.component && (
                      <Route
                        key={`route-${route.path}`}
                        path={route.path}
                        exact={route.exact}
                        name={route.name}
                        render={(routeProps) => (
                          <route.component
                            location={routeProps.location}
                            history={routeProps.history}
                            match={routeProps.match}
                          />
                        )}
                      />
                    )
                  ))}
                  {firstPath && <Redirect from="/" to={firstPath} />}
                </Switch>
              </Suspense>
            </Container>
          </main>
        </div>
        <AppFooter>
          <DefaultFooter />
        </AppFooter>
      </div>
    );
  }
}

DefaultLayout.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
    hash: PropTypes.string,
    state: PropTypes.shape({})
  }).isRequired,
  permissions: PropTypes.shape({
    loading: PropTypes.bool,
    data: PropTypes.shape({
      superAdmin: PropTypes.bool,
      user: PropTypes.shape({
        merchantId: PropTypes.string,
        userId: PropTypes.string,
        fullName: PropTypes.string
      }),
      permissionGroups: PropTypes.arrayOf(PropTypes.string)
    }),
    error: PropTypes.shape({
      message: PropTypes.string
    })
  }),
  getUserPermissions: PropTypes.func.isRequired
};

DefaultLayout.defaultProps = {
  permissions: {
    loading: false,
    data: {
      superAdmin: false,
      user: null,
      permissionGroups: []
    },
    error: null
  }
};

const mapStateToProps = (state) => ({
  permissions: state.permissionsState
});
const mapDispatchToProps = {
  getUserPermissions
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DefaultLayout);
