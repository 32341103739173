import { GET_USER_PERMISSIONS } from '../actionTypes/permissions';

const initialState = {
  loading: true,
  data: {
    superAdmin: false,
    user: null,
    permissionGroups: []
  },
  error: null
};

export default function PermissionsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_USER_PERMISSIONS:
      return {
        ...state,
        loading: action.state,
        data: action.payload || initialState.data,
        error: action.error || null
      };
    default:
      return state;
  }
}
