import React from "react";

// eslint-disable-next-line import/no-cycle
import DefaultLayout from "../containers/defaultLayout";

const Dashboard = React.lazy(() => import("../views/dashboard/dashboard"));

const Schedule = React.lazy(() => import("../views/schedule/schedule"));

const InstallmentList = React.lazy(() =>
  import("../views/receivable/installmentList")
);

const OrderDetail = React.lazy(() => import("../views/order/orderDetail"));

const OrderList = React.lazy(() => import("../views/order/orderList"));

const SubscriptionList = React.lazy(() =>
  import("../views/subscription/subscriptionList")
);

const SubscriptionDetail = React.lazy(() =>
  import("../views/subscription/subscriptionDetail")
);

const TransactionList = React.lazy(() =>
  import("../views/transaction/transactionList")
);

const TransactionDetail = React.lazy(() =>
  import("../views/transaction/transactionDetail")
);

const Access = React.lazy(() => import("../views/access/access"));

const Defaulters = React.lazy(() => import("../views/defaulters"));

const routes = [
  {
    path: "/",
    exact: true,
    name: "Home",
    component: DefaultLayout,
    groups: [
      "admin",
      "dashboard",
      "schedule",
      "sales",
      "order-writing",
      "order-reading",
      "subscriptions-writing",
      "subscriptions-reading",
    ],
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    groups: [
      "admin",
      "dashboard"
    ],
  },
  {
    path: "/agenda",
    exact: true,
    name: "Agenda",
    component: Schedule,
    groups: ["schedule", "admin"]
    ,
  },
  {
    path: "/pedidos/detalhes/:uuid/:code",
    exact: true,
    name: "Detalhes",
    component: OrderDetail,
    groups: ["order-writing", "order-reading", "admin"]
    ,
  },
  {
    path: "/agenda/parcelas/:uuid",
    exact: true,
    name: "Parcelas",
    component: InstallmentList,
    groups: ["schedule", "admin"]
    ,
  },
  {
    path: "/agenda/detalhes/:uuid",
    exact: true,
    name: "Detalhes",
    component: TransactionDetail,
    groups: ["schedule", "admin"]
    ,
  },
  {
    path: "/pedidos",
    exact: true,
    name: "Pedidos",
    component: OrderList,
    groups: ["order-writing", "order-reading", "admin"]
    ,
  },
  {
    path: "/assinaturas",
    exact: true,
    name: "Assinaturas",
    component: SubscriptionList,
    groups: ["subscriptions-writing", "subscriptions-reading", "admin"]
    ,
  },
  {
    path: "/assinaturas/detalhes/:uuid/:code",
    exact: true,
    name: "Detalhes",
    component: SubscriptionDetail,
    groups: ["subscriptions-writing", "subscriptions-reading", "admin"]
    ,
  },
  {
    path: "/vendas",
    exact: true,
    name: "Vendas",
    component: TransactionList,
    groups: ["sales", "admin"]
    ,
  },
  {
    path: "/vendas/detalhes/:uuid",
    exact: true,
    name: "Detalhes",
    component: TransactionDetail,
    groups: ["sales", "admin"]
    ,
  },
  {
    path: "/gestao_acessos",
    exact: true,
    name: "Gestão de Acessos",
    component: Access,
    groups: ["admin"]
    ,
  },
  {
    path: "/defaulters",
    exact: true,
    name: "Inadimplentes",
    component: Defaulters,
    groups: ["sales", "admin"]
    ,
  },
];

export default routes;
