export default {
  END_POINT:
    process.env.REACT_APP_API_BASE_URL ||
    "https://homol.portal.gateway.pay2b.net",
  HOST_NAME: "sandbox.mapfre.portal.pay2b.net",
  EXPIRATION_AUTH: 3600000, // Minuts
  EXPIRATION_FORM: 5, // Minuts
  DEBUG: {
    LEVEL: "debug",
    AVAILABLE: true,
    LOCAL: true,
  },
  USER: {
    URL: "/oauth",
    USER: "/user",
    AUTH: "/login",
    FIRST_LOGIN: "/first_login",
    FORGOT_PASSWORD: "/forgot_password",
    CONFIRM: "/confirm",
    LOGOUT: "/logout",
  },
  CATEGORY: {
    URL: "/category",
  },
  USER_PERMISSION: {
    URL: "/users/permissions",
  },
  PERMISSION: {
    URL: "/security/permission",
  },
  GROUP: {
    URL: "/oauth/groups",
  },
  PAYMENT: {
    URL: "/paymentmethod",
  },
  PAYMENT_OPTION: {
    URL: "/paymentmethod/options",
  },
  BANK: {
    URL: "/bank",
  },
  OPTIN: {
    URL: "/optin",
  },
  SCHEDULE: {
    URL: "/receivable",
    INSTALLMENTS: "/receivable/installments",
  },
  TRANSFER: {
    URL: "/receivable/transfer",
    CONFIRM: "/receivable",
  },
  RECEIVABLE: {
    URL: "/receivable",
    FILTER: "/receivable/filter",
    DOWNLOAD: "/receivable/download",
    INSTALLMENTS_DOWNLOAD: "/receivable/installments/download",
    INSTALLMENTS_DOWNLOAD_SCHEDULE:
      "/receivable/installments/schedule/download",
    INSTALLMENTS: "/receivable/installments",
    MONTHLY: "/receivable/monthly",
  },
  STORE: {
    URL: "/store",
    BY_STORE: "/by_store",
    BRANCH: "/store/branches",
    WHITE_LABELS: "/whitelabels",
  },
  STORE_TYPE: {
    URL: "/store/type",
  },
  SHOPPING: {
    URL: "/shopping",
  },
  CUSTOMER: {
    URL: "/customer",
  },
  ORDER: {
    URL: "/order",
    DOWNLOAD: "/download_letter",
  },
  SUBSCRIPTION: {
    URL: "/subscription",
    CYCLE: "/cycle",
    REFUND: "/refund",
    CANCEL: "/cancel",
    DOWNLOAD: "/download_letter",
  },
  DASHBOARD: {
    URL: "/store",
    DASHBOARD: "/dashboard",
    SMAUG: "/smaug",
    PAYMENT: "/payment",
    BALANCE: "/balance",
  },
  TRANSACTION: {
    URL: "/transaction",
    INDICATORS: "/transaction/indicators",
    ABSTRACT: "/transaction/abstract",
    DOWNLOAD: "/transaction/csvFile/download",
  },
  DEFAULTER: {
    ORDER: "/defaulter/order",
    ORDER_DOWNLOAD: "/defaulter/order/download",
    SUBSCRIPTION: "/defaulter/subscription",
    SUBSCRIPTION_DOWNLOAD: "/defaulter/subscription/download",
  },
};
